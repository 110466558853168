<template>
  <v-container fluid>
    <v-row class="ma-3" wrap>
      <!-- Proposta -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          :items="propostas"
          :loading="loadings.loadingProposta"
          v-model="contrato.id_proposta"
          ref="proposta"
          label="Proposta"
          placeholder="Selecione uma proposta da empresa"
          item-text="nome_responsavel"
          item-value="id"
          dense
          outlined
          data-vv-as="proposta"
          persistent-hint
          :hint="errors.first('adm_contratos.id_proposta')"
          :error="
            errors.collect('adm_contratos.id_proposta').length ? true : false
          "
          v-validate="'required'"
          data-vv-scope="adm_contratos"
          data-vv-validate-on="change"
          id="id_proposta"
          name="id_proposta"
        >
        </v-select>
      </v-col>
      <!-- Filial -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          :items="filiais"
          v-model="contrato.id_filial"
          :loading="loadings.loadingFilial"
          ref="filial"
          label="Filial"
          placeholder="Uma filial da empresa"
          item-text="nome"
          item-value="id"
          dense
          outlined
          clearable
        ></v-select>
      </v-col>
      <!-- Tipo -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          :items="tipo"
          v-model="contrato.tipo"
          ref="tipo"
          label="Tipo"
          placeholder="Selecione o tipo"
          dense
          outlined
          data-vv-as="tipo"
          persistent-hint
          :hint="errors.first('adm_contratos.tipo')"
          :error="errors.collect('adm_contratos.tipo').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_contratos"
          data-vv-validate-on="change"
          id="tipo"
          name="tipo"
        ></v-select>
      </v-col>
      <!-- Arquivo -->
      <v-col cols="12" sm="6" md="6">
        <div
          v-if="typeof contrato.arquivo == 'string'"
          class="d-flex flex-row align-center justify-start"
        >
          <p class="ma-0 pa-0 text-truncate">{{ contrato.arquivo }}</p>
          <v-btn @click="contrato.arquivo = null" icon class="ml-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-file-input
          v-else
          v-model="contrato.arquivo"
          accept="application/pdf, application/doc, application/docx"
          placeholder="Colocar arquivo"
          label="Arquivo"
          data-vv-as="arquivo"
          persistent-hint
          :hint="errors.first('adm_contratos.arquivo')"
          :error="errors.collect('adm_contratos.arquivo').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_contratos"
          data-vv-validate-on="change"
          id="arquivo"
          name="arquivo"
        ></v-file-input>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ContratosForm",
  inject: ["$validator"],
  data() {
    return {
      tipo: ["contrato", "aditivo"],
      loadings: {
        loadingFilial: true,
        loadingProposta: true,
      },
    };
  },
  async created() {
    const dataProposta = {
      columns: ["id", "nome_responsavel"],
      validate: [["status", "Aberta"]],
    };
    const dataFiliais = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };

    await this.$store
      .dispatch("propostas/findByColumns", dataProposta)
      .finally(() => {
        this.loadings.loadingProposta = false;
      });

    await this.$store
      .dispatch("filiais/findByColumns", dataFiliais)
      .finally(() => {
        this.loadings.loadingFilial = false;
      });
  },
  computed: {
    contrato: {
      get() {
        return this.$store.getters["contratos/getContrato"];
      },
      set(value) {
        this.$store.dispatch("contratos/setContrato", value);
      },
    },
    filiais: {
      get() {
        return this.$store.getters["filiais/getFiliais"];
      },
    },
    propostas: {
      get() {
        return this.$store.getters["propostas/getPropostas"];
      },
    },
  },
};
</script>

<style></style>
