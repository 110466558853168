<template>
  <section name="contratos">
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Contratos"
          buttonAddItemText="Novo Contrato"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="data-content">
            <v-row class="mt-5">
              <!-- FILTRO CAMPOS -->
            </v-row>
          </template>
          <template slot="data-table">
            <v-data-table
              :headers="
                headers.filter((header) => {
                  return header.value != 'editar' && header.value != 'acao';
                })
              "
              :items="contratos"
              class="elevation-5"
              :loading="loadingTable"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <!-- Visualizar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="toggleModalLateral(item)"
                              >
                                mdi-eye
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Visualizar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Editar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon class="actions" @click="editar(item)">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Deletar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="remover(item, true)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Deletar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch
                  class="mt-0 pt-0"
                  v-model="item.status"
                  @click="mudarStatus(item)"
                  :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                  true-value="A"
                  false-value="I"
                  color="success"
                  hide-details
                ></v-switch>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <span
                  >{{ item.created_at | dateFormatBr() }}
                  {{ item.created_at | timeFormatBr() }}h</span
                >
              </template>
              <template v-slot:[`item.proposta`]="{ item }">
                <span v-if="item.proposta && item.proposta.nome_responsavel">
                  {{ item.proposta.nome_responsavel }}
                </span>
                <v-chip v-else small color="error">
                  <v-icon class="mr-1"> mdi-information-outline </v-icon> Não
                  tem proposta
                </v-chip>
              </template>
              <template v-slot:[`item.arquivo`]="{ item }">
                <v-btn
                  class="ma-0 pa-0 px-1 text-truncate"
                  color="accent"
                  link
                  text
                  :href="`${item.url}/${item.arquivo}`"
                  target="_blank"
                >
                  {{ item.arquivo }}
                </v-btn>
              </template>
              <template v-slot:[`item.tipo`]="{ item }">
                <span>{{ item.tipo | capitalize() }}</span>
              </template>
            </v-data-table>
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">
            Excluir o registro?
          </template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn
              :loading="loadingButtonDelete"
              color="error"
              @click="remover()"
            >
              Excluir
            </v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
          <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <ContratosForm />
          </template>
        </Crud>
      </v-col>
    </v-row>

    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="item.status == 'A' ? 'success' : 'secondary'">{{
          item.status == "A" ? "Ativo" : "Inativo"
        }}</v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span
          >{{ item.updated_at | dateFormatBr() }}
          {{ item.updated_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.filial`]="{ item }">
        <span v-if="item.filial && item.filial.nome">
          {{ item.filial.nome }}
        </span>
        <v-chip small color="accent" dark v-else>
          <v-icon class="mr-1"> mdi-information-outline </v-icon> Não tem filial
        </v-chip>
      </template>
      <template v-slot:[`item.arquivo`]="{ item }">
        <v-btn
          class="ma-0 pa-0 px-1 text-truncate"
          color="accent"
          link
          text
          :href="`${item.url}/${item.arquivo}`"
          target="_blank"
        >
          {{ item.arquivo }}
        </v-btn>
      </template>
      <template v-slot:[`item.tipo`]="{ item }">
        <span>{{ item.tipo | capitalize() }}</span>
      </template>
      <template v-slot:[`item.proposta`]="{ item }">
        <v-list
          v-if="item.proposta && item.proposta.nome_responsavel"
          class="ma-0 pa-0"
        >
          <v-list-item two-line class="ma-0 pa-0">
            <v-list-item-content class="ma-0 pa-0">
              <v-list-item-title>
                {{ item.proposta.nome_responsavel }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.proposta.cnpj | cnpjMask() }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-chip v-else small color="error">
          <v-icon class="mr-1"> mdi-information-outline </v-icon> Não tem
          proposta
        </v-chip>
      </template>
    </ModalLateral>
  </section>
</template>

<script>
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
import ContratosForm from "./forms/ContratosForm.vue";
import FormData from "form-data";

export default {
  name: "Contratos",
  inject: ["$validator"],
  components: {
    Crud,
    ModalLateral,
    ContratosForm,
  },
  data() {
    return {
      nameForm: "Novo Contrato",
      modalLateral: {
        show: false,
        item: [],
        title: "",
      },
      searchTextField: "",
      dialog: false,
      loadingTable: false,
      headers: [
        { text: "Proposta de", value: "proposta" },
        { text: "Tipo da proposta", value: "tipo" },
        { text: "Arquivo", value: "arquivo" },
        { text: "Status", value: "status" },
        { text: "Data de criação", value: "created_at" },
        { text: "Ações", value: "actions" },
      ],
      headerModalLateral: [
        { text: "Proposta de", value: "proposta" },
        { text: "Filial", value: "filial" },
        { text: "Tipo da proposta", value: "tipo" },
        { text: "Arquivo", value: "arquivo" },
        { text: "Status", value: "status" },
        { text: "Data de criação", value: "created_at" },
        { text: "Data de atualização", value: "updated_at" },
      ],

      dialogDelete: false,
      loadingButtonDelete: false,
      item: [],
    };
  },

  created() {
    this.loadingTable = true;

    this.$store.dispatch("contratos/initialize").finally(() => {
      this.loadingTable = false;
    });
  },

  computed: {
    contratos: {
      get() {
        return this.$store.getters["contratos/getContratos"];
      },
    },
    contrato: {
      get() {
        return this.$store.getters["contratos/getContrato"];
      },
      set(value) {
        this.$store.dispatch("contratos/setContrato", value);
      },
    },
    empresa: {
      get() {
        return this.$store.getters["empresas/getEmpresaSelecionada"];
      },
    },
  },

  methods: {
    salvar() {
      this.$validator.validate("adm_contratos.*").then((result) => {
        if (result) {
          const isEdit = this.contrato.id || this.contrato.uuid ? 1 : -1;

          this.dialog = false;
          this.loadingTable = true;
          let url =
            isEdit === -1 ? "/contratos" : "/contratos/" + this.contrato.uuid;
          let method = isEdit === -1 ? "POST" : "PUT";
          let sucesso =
            isEdit === -1
              ? "Contrato cadastrado com sucesso."
              : "Contrato atualizado com sucesso.";
          let erro =
            isEdit === -1
              ? "Erro ao salvar contrato."
              : "Erro ao atualizar contrato.";

          let formData = new FormData();
          formData.append("id", this.contrato.id ? this.contrato.id : null);
          formData.append("id_filial", this.contrato.id_filial);
          formData.append(
            "id_empresa",
            this.contrato.id_empresa ? this.contrato.id_empresa : null
          );
          formData.append("id_proposta", this.contrato.id_proposta);
          formData.append("tipo", this.contrato.tipo);
          formData.append("arquivo", this.contrato.arquivo);
          formData.append("status", this.contrato.status);
          formData.append("_method", method);

          let data = {
            data: formData,
            url: url,
          };

          this.$store
            .dispatch("contratos/save", data)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                if (isEdit === -1) {
                  this.contratos.push(response);
                } else {
                  this.getContractUpdated();
                }

                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: sucesso,
                  cor: "green",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: erro,
                cor: "red",
              });
            });
        }
      });
    },
    editar(item) {
      this.loadingTable = true;

      this.$store
        .dispatch("contratos/findByUuid", item.uuid)
        .then((response) => {
          this.contrato = response;
          this.nameForm = "Editar contrato: " + this.contrato.nome;
          this.dialog = true;
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Houve um erro ao buscar todos os dados do contrato selecionado.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    fechar() {
      this.dialog = false;
      this.loadingTable = false;
      this.nameForm = "Novo contrato";
      this.contrato = {
        id_filial: null,
        id_proposta: null,
        tipo: null,
        arquivo: null,
        status: "A",
      };
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.contratos.findIndex(
          (el) => el.id == this.item.id
        );

        this.$store
          .dispatch("contratos/remove", this.item.uuid)
          .then((response) => {
            if (response) {
              this.contratos.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Contrato removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possível remover o contrato.",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
          });
      }
    },
    mudarStatus(item) {
      this.loadingTable = true;

      let data = {
        data: { status: item.status, _method: "PUT" },
        url: "/contratos/" + item.uuid,
      };

      this.$store
        .dispatch("contratos/save", data)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Contrato atualizado com sucesso.",
              cor: "green",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar contrato.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    //Modal Lateral
    toggleModalLateral(item) {
      if (this.modalLateral.show == false) {
        this.loadingTable = true;

        this.$store
          .dispatch("contratos/findByUuid", item.uuid)
          .then((response) => {
            this.modalLateral.title = response.nome;
            this.modalLateral.item = response ? response : [];
            this.modalLateral.show = true;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem:
                "Houve um erro ao buscar todos os dados do contrato selecionado.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadingTable = false;
          });
      } else {
        this.modalLateral.show = false;
      }
    },
    getContractUpdated() {
      this.$store
        .dispatch("contratos/findByUuid", this.contrato.uuid)
        .then((response) => {
          const index = this.contratos.findIndex(
            (el) => el.id == this.contrato.id
          );

          Object.assign(this.contratos[index], response);

          this.fechar();
        });
    },
  },
};
</script>

<style scoped></style>
